<template>
    <div>
    <div class="content_bottom">
      <h2 class="heading">完整的产品+服务，提升您的数字化运营能力</h2>
      <p class="slogan">私域流量全覆盖，完整提供营销服务闭环和解决方案</p>
      <div class="service">
        <div
          v-for="(v, index) in servicelist"
          :key="index"
          class="service_c"
        >
          <img :src="v.imgurl" />
          <h6>{{ v.sname }}</h6>
          <p>{{ v.content }}</p>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
      // 服务列表
      servicelist: [
        {
          sname: "点对点专有系统培训服务",
          imgurl: require("../assets/images/service01.png"),
          content: "为企业制定系统的培训服务，协助企业数字化运营落地运行。",
        },
        {
          sname: "深度sop运营服务",
          imgurl: require("../assets/images/service02.png"),
          content:
            "根据行业基础业务场景，专人到店深度定制SOP服务，整合线上＋线下服务流程，对业务场景统一管理和运营。",
        },
        {
          sname: "提供合作运营分成方案",
          imgurl: require("../assets/images/service03.png"),
          content:
            "无需基础搭建成本，完善的合作运营分成方案，助企业轻松搭建数字化营销场景。",
        },
      ],
        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang="less">
.content_bottom {
  padding-top: 1rem;
  .service {
    margin: 0.58rem 2.4rem 0;
    display: flex;
    justify-content: space-between;
    height: 5rem;
    .service_c {
      width: 4.6rem;
      height: 4.11rem;
      box-sizing: border-box;
      padding: 0.32rem;
      box-shadow: 0.1rem 0.1rem 0.3rem 0.01rem rgba(0, 0, 115, 0.13);
      img {
        width: 3.96rem;
        height: 2rem;
      }
      h6 {
        font-size: 0.22rem;
        width: 2.42rem;
        height: 0.45rem;
        padding: 0.1rem 0 0;
        margin: 0 auto;
        text-align: center;
        line-height: 0.3rem;
      }
      p {
        text-align: center;
        font-size: 0.18rem;
        color: #666;
        line-height: 0.27rem;
      }
    }

  }
}
</style>
