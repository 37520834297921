<template>
  <div class="whole">
    <!-- 头部导航 -->
    <el-header>
      <img class="logo" src="../assets/images/LOGO.png" />
      <ul class="nav">
        <li
          :class="check == 0 ? 'Check' : ''"
          @mousemove="onlink(0)"
          @mouseout="offlink"
        >
          <p>首页</p>
        </li>
        <li
          v-for="(v, index) in navlist"
          :key="index"
          :class="check == index + 1 ? 'Check' : ''"
          @mousemove="onlink(index + 1)"
          @mouseout="offlink"
        >
          <p>{{ v.name }}</p>
          <div class="c_nav">
            <p
              v-for="(item, i) in v.projectlist"
              :key="i"
              @mousemove="oncheck(i)"
              :class="c_check == i ? 'c_check' : ''"
              @click="goabout(item.rname)"
            >
              {{ item.pjname }}
            </p>
          </div>
        </li>
      </ul>
      <!-- <div class="search">
        <img src="../assets/images/icon_sousuo.png" />
        <input type="text" placeholder="搜索" />
      </div> -->
      <button @click="gowxd">登录</button>
      <button class="trial" @click="onapply()">免费试用</button>
    </el-header>
    <!-- 轮播图 -->
    <el-carousel :interval="6000" trigger="click">
      <el-carousel-item v-for="(v, index) in carousel" :key="index">
        <div class="swp">
          <div class="swp_l">
            <h1>{{ v.title }}</h1>
            <p>{{ v.introduce }}</p>
            <div style="padding-top: 0.33rem">
              <button @click="onapply()">免费试用</button>
            </div>
          </div>
          <img :src="v.imgurl" alt="" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 全链路CRMC产品 -->
    <System />
    <!-- CDP客户数据平台 -->
    <div class="content_middle">
      <h2 class="heading">CDP客户数据平台</h2>
      <p class="slogan">沉淀私域流量，精细化会员管理</p>
      <img src="../assets/images/CDP.png" />
    </div>
    <!-- 完整的产品+服务，提升您的数字化运营能力 -->
    <Service />
    <!-- 会员营销场景和行业案例 -->
    <Caselisting :childEvent="getapply" />
    <!-- 500＋企业的共同选择 -->
    <div class="cooperation">
      <h2 class="heading">500＋企业的共同选择</h2>
      <p class="slogan">千人千面的策略布置</p>
      <img src="../assets/images/hzqy.png" />
    </div>
    <Apply class="Apply" v-show="apply" :childEvent="getapply" />
    <!-- 尾部导航栏 -->
    <div class="footer">
      <div class="footer_l">
        <p class="company">
          <img class="company_logo" src="../assets/images/logo_b.png" />
        </p>
        <img class="rwm" src="../assets/images/rwm.png" />
        <p class="tips">
          <img src="../assets/images/icon_weixin.png" alt="" />
          <span>扫码体验，微心动</span>
        </p>
        <p class="tips">
          <img src="../assets/images/icon_guanzhu.png" alt="" />
          <span>关注微心动，了解更多私域运营知识！</span>
        </p>
      </div>
      <div class="footer_r">
        <div class="footer_nav">
          <div class="nav_c" v-for="(v, index) in navlist" :key="index">
            <h6>{{ v.name }}</h6>
            <p
              v-for="(item, i) in v.projectlist"
              :key="i"
              @click="bottomclick(item.rname, index)"
            >
              {{ item.pjname }}
            </p>
          </div>
        </div>
        <div class="address">
          <div>
            <p>邮箱：hr@sindon.cn</p>
            <p>地址：成都市武侯区天益街38号理想中心1栋1306</p>
          </div>
          <!-- <img src="../assets/images/LOGO.png" /> -->
        </div>
      </div>
    </div>
    <div class="identifier">
      <a href="https://beian.miit.gov.cn/">蜀ICP备12021355号-1</a>
      开发者：成都微心动科技有限公司
    </div>
  </div>
</template>

<script>
import System from "../components/System.vue";
import Service from "../components/Service.vue";
import Caselisting from "../components/Caselisting.vue";
import Apply from "../components/Apply.vue";
export default {
  components: {
    System,
    Service,
    Caselisting,
    Apply,
  },
  data() {
    return {
      // 导航栏
      navlist: [
        {
          name: "CRMC产品",
          projectlist: [
            {
              pjname: "会员权益体系",
              rname: "Equity",
            },
            {
              pjname: "会员分销体系",
              rname: "Distribution",
            },
            {
              pjname: "会员商城体系",
              rname: "Shopping",
            },
            {
              pjname: "会员行为偏好分析&预测体系",
              rname: "Analysis",
            },
            {
              pjname: "会员综合bi平台",
              rname: "Platform",
            },
          ],
        },
        {
          name: "营销应用场景",
          projectlist: [
            {
              pjname: "数字化营销",
              rname: "Digitization",
            },
            {
              pjname: "私域流量运营",
              rname: "Flow",
            },
            {
              pjname: "全渠道SCRM管理平台",
              rname: "SCRM",
            },
            {
              pjname: "营销活动管理",
              rname: "Activity",
            },
            {
              pjname: "会员营销管理",
              rname: "Member",
            },
            {
              pjname: "构建企业级的用户画像体系",
              rname: "Enterprise",
            },
            {
              pjname: "智能化用户标签体系构建",
              rname: "Label",
            },
          ],
        },
        {
          name: "行业案例",
          projectlist: [
            {
              pjname: "酒店行业解决方案",
              rname: "Hotelscheme",
            },
            {
              pjname: "长租式公寓行业解决方案",
              rname: "Apartmentscheme",
            },
            {
              pjname: "大中型连锁餐饮行业解决方案",
              rname: "Cateringprogram",
            },
          ],
        },
        {
          name: "工具与资讯",
          projectlist: [
            {
              pjname: "产品更新",
              rname: "Product",
            },
            {
              pjname: "新闻洞察",
              rname: "News",
            },
            {
              pjname: "心动丨资讯",
              rname: "Consulting",
            },
            {
              pjname: "智能营销主题生成器",
              rname: "Generate",
            },
          ],
        },
        {
          name: "我们",
          projectlist: [
            {
              pjname: "关于我们",
              rname: "Aboutus",
            },
            {
              pjname: "联系我们",
              rname: "Aboutus",
            },
            // {
            //   pjname: "加入我们",
            //   rname: "Joinus",
            // },
            {
              pjname: "合作伙伴",
              rname: "Cooperation",
            },
          ],
        },
      ],
      check: 0,
      mousemove: 0,
      c_check: 0,
      // 轮播图内容
      carousel: [
        {
          title: "打造农业新生态！",
          introduce:
            "成都微心动科技有限公司与四川省供销科技产业集团就助力智慧农业数字化发展达成全面合作",
          imgurl: require("../assets/images/banner04.png"),
        },
        {
          title: "微心动x逸本源科技有限公司",
          introduce:
            "中标中国邮政储蓄银行省分行会员/企业权益项目奋楫扬帆正当时，齐心协力创佳绩！",
          imgurl: require("../assets/images/banner05.png"),
        },
        {
          title: "心动赢客会员体系",
          introduce: "深耕企业数字科技，实现营收增长",
          imgurl: require("../assets/images/banner01.png"),
        },
        {
          title: "全渠道会员数字化营销平台",
          introduce: "沉淀私域流量，搭建自有获客渠道；完善&提升整体会员机制",
          imgurl: require("../assets/images/banner02.png"),
        },
        {
          title: "全渠道会员数据运营中心",
          introduce:
            "微信生态，抖音，小红书，自有crm；Pms全域数据直连，形成整体数据处理中心",
          imgurl: require("../assets/images/banner03.png"),
        },
      ],
      apply: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 导航栏
    onlink(i) {
      this.mousemove = i;
    },
    oncheck(i) {
      this.c_check = i;
    },
    offlink() {
      this.mousemove = 0;
      this.c_check = 0;
    },
    goabout(a) {
      if (a == "Generate") {
        window.location.href = "https://www.convertlab.com/yourtitles/";
        return;
      }
      let index = this.mousemove;
      this.$router.push({
        name: a,
        query: {
          id: index,
        },
      });
    },
    bottomclick(a, i) {
      if (a == "Generate") {
        window.location.href = "https://www.convertlab.com/yourtitles/";
        return;
      }
      this.$router.push({
        name: a,
        query: {
          id: i + 1,
        },
      });
    },
    // 点击试用
    onapply() {
      // this.apply = true;
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
    getapply(data) {
      console.log(111);
      this.apply = data;
    },
    gowxd() {
      window.location.href = "http://hotel.sindon.cn/new/login";
    },
  },
};
</script>
<style lang="less">
// 头部导航栏
.logo {
  width: 2rem;
  height: 0.38rem;
  margin: 0.31rem 2.1rem 0.31rem 2.4rem;
}
.el-header {
  background-color: #ffc53d;
  color: #333;
  line-height: 1rem;
  height: 0.8rem !important;
  display: flex;
  .nav {
    display: flex;
    font-size: 0.22rem;
    font-weight: 600;
    li {
      padding: 0 0.16rem;
      position: relative;
      cursor: pointer;
      .c_nav {
        display: none;
        line-height: 0.2rem;
        width: 3rem;
        font-size: 0.2rem;
        background-color: #ffd980;
        border-radius: 0.05rem;
        color: #444;
        position: absolute;
        top: 0.8rem;
        left: 0;
        z-index: 999;
        padding-left: 0.16rem;
        padding-top: 0.1rem;
        p {
          padding: 0.11rem 0.1rem;
        }
        p:last-child {
          padding-bottom: 0.2rem;
        }
      }
    }
  }
  button {
    width: 1.16rem;
    height: 0.48rem;
    background-color: #ffc53d;
    color: #333;
    border: 0.02rem solid #333;
    margin: 0.23rem 0.23rem 0.23rem 0.6rem;
    border-radius: 0.05rem;
  }
  .trial {
    width: 1.16rem;
    height: 0.48rem;
    background-color: #333;
    color: #ffc53d;
    border: none;
    margin: 0.23rem 0.23rem 0.23rem 0rem;
    border-radius: 0.05rem;
  }
  .Check {
    border-bottom: 0.04rem solid #333;
  }
  .c_check {
    color: #fff;
  }
}

// 轮播图
.el-carousel__container {
  height: 6.6rem !important;
}
.el-carousel__item .swp {
  height: 6.6rem;
  margin: 0.63rem 1.43rem;
  color: #333;
  display: flex;
  .swp_l {
    padding-top: 0.83rem;
    width: 8.4rem;
    h1 {
      font-size: 0.64rem;
      line-height: 1.2rem;
    }
    p {
      font-size: 0.4rem;
      line-height: 0.5rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border: 0;
      font-size: 0.2rem;
      border-radius: 0.05rem;
      margin-right: 0.36rem;
    }
  }
  img {
    margin-left: 0.6rem;
    width: 7.2rem;
    height: 4.6rem;
  }
}

.el-carousel__item {
  background-color: #ffc53d;
}

// CDP客户数据平台
.content_middle {
  padding-top: 1rem;
  text-align: center;
  img {
    padding-top: 0.58rem;
    width: 14.43rem;
    height: 7.62rem;
  }
}
// 500＋企业的共同选择
.cooperation {
  padding-top: 1rem;
  text-align: center;
  img {
    padding-top: 0rem;
    width: 19.2rem;
    height: 7.88rem;
  }
}

// 尾部导航栏
.footer {
  background-color: #666666;
  color: #fff;
  padding: 0 0.64rem 0.3rem;
  display: flex;
  .footer_l {
    padding: 0.58rem 0.9rem;
    .company {
      font-size: 0.18rem;
      letter-spacing: 0.015rem;
      .company_logo {
        padding-top: 0.01rem;
        width: 3.17rem;
        height: 1.39rem;
        display: block;
        margin-bottom: 0.2rem;
      }
    }
    .phone {
      font-size: 0.4rem;
      line-height: 0.76rem;
    }
    .rwm {
      width: 2rem;
      height: 2rem;
    }
    .tips {
      display: flex;
      align-items: center;
      img {
        width: 0.3rem;
        height: 0.3rem;
      }
      span {
        font-size: 0.2rem;
        line-height: 0.56rem;
        padding-left: 0.05rem;
      }
    }
  }
  .footer_r {
    padding-top: 0.64rem;
    flex: 1;
    .footer_nav {
      display: flex;
      justify-content: space-between;
      .nav_c {
        width: 2.1rem;
        h6 {
          font-size: 0.22rem;
          font-weight: 500;
          padding-bottom: 0.14rem;
          border-bottom: 0.02rem solid #fff;
        }
        p {
          color: #f2f2f2;
          font-size: 0.16rem;
          line-height: 0.36rem;
          cursor: pointer;
        }
      }
    }
    .address {
      margin-top: 0.8rem;
      display: flex;
      justify-content: space-between;
      div {
        font-size: 0.2rem;
        line-height: 0.56rem;
      }
      img {
        margin-top: 0.8rem;
        width: 0.84rem;
        height: 0.16rem;
      }
    }
  }
}

.identifier {
  height: 0.64rem;
  font-size: 0.16rem;
  line-height: 0.64rem;
  background-color: #525252;
  color: #fff;
  text-align: center;
  a {
    color: #fff;
    padding-right: 0.28rem;
  }
}
// 公共
.heading {
  font-size: 0.28rem;
  // line-height: 0.6rem;
  text-align: center;
  padding-bottom: 0.2rem;
  color: #333;
}
.slogan {
  font-size: 0.18rem;
  color: #666;
  text-align: center;
}

// 试用

.Apply {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

li:hover .c_nav {
  display: block !important;
  overflow: hidden;
  animation: move 1s;
}
@keyframes move {
  0% {
    top: 0.8rem;
  }
  50% {
    top: 1.2rem;
  }
  100% {
    top: 0.8rem;
  }
}
</style>