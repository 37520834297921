<template>
  <div>
    <div class="content_top">
      <h2 class="heading">全链路CRMC产品</h2>
      <p class="slogan">适用服务场景多元化，助企业打造行业标杆</p>
      <div class="systemlist">
        <p
          v-for="(v, index) in systemlist"
          :key="index"
          :class="system_check == index ? 'system_check' : ''"
          @click="getsystem(index)"
        >
          {{ v.name }}
        </p>
      </div>

      <div class="system_c">
        <div class="system_x">
          <h5>{{ systemlist[system_check].title }}</h5>
          <p
            v-for="(v, index) in systemlist[system_check].systemcontent"
            :key="index"
          >
            <span></span>{{ v }}
          </p>
        </div>
        <img   :src="systemlist[system_check].imgurl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      systemlist: [
        {
          name: "会员权益体系",
          title: "建立企业私域流量会员权益体系，促进用户增长和行业发展",
          imgurl: require("../assets/images/wa01.png"),
          systemcontent: [
            "建立会员权益体系",
            "会员权益的设计",
            "设定用户使用率高、有兴趣的核心权益",
            "提高会员权益的使用率和兑换率",
            "多元化的营销主题活动",
            "完善的会员体系需要控制机制和数据监控",
          ],
        },
        {
          name: "会员分销体系",
          title: "助力搭建B2B数字化创新平台，赋能B端业务场景，助力渠道模式升级",
          imgurl: require("../assets/images/wa02.png"),
          systemcontent: [
            "搭建多级分销体系",
            "支持多级分销业务",
            "拓展销售新渠道",
            "全链路数字营销化",
            "用户统一数据管理",
            "助力渠道模式升级",
          ],
        },
        {
          name: "会员商城体系",
          title:
            "沉淀私域流量，打造零售业务闭环，助企业打造生态级的社群营销体系",
          imgurl: require("../assets/images/wa05.png"),
          systemcontent: [
            "多终端B2C线上商城",
            "线上线下融合",
            "多种营销玩法",
            "统一高效运营",
            "满足多样化的场景需求",
          ],
        },
        {
          name: "会员行为偏好分析&预测体系",
          title: "助力企业深度洞察消费者，赋能精细化运营",
          imgurl: require("../assets/images/wa03.png"),
          systemcontent: [
            "构建会员分析模型",
            "分析不同等级会员的消费偏好",
            "精准定向推送活动信息",
            "持续监测会员行为",
            "建立体系化会员标签和预测体系",
            "完善的会员解决方案",
          ],
        },
        {
          name: "会员综合bi平台",
          title: "赋能会员全生命周期价值，驱动企业业务增长",
          imgurl: require("../assets/images/wa04.png"),
          systemcontent: [
            "搭建会员全生命周期管理综合bi平台",
            "会员的数据化管理、基础分析数据和价值指标分析",
            "专业效能的智能剖析",
            "建立系统化、多元化的分析方案",
            "最具性价比的数据分析和信息化综合平台",
          ],
        },
      ],
      system_check: 0,
      show:false
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 体系
    getsystem(i) {
      let that=this
      this.system_check = i;
        this.show=true
     let aa=setTimeout(()=>{
       that.show=false
       clearInterval(aa)
     },3000)
     aa
    },
  },
};
</script>

<style scoped lang="less">
.content_top {
  padding-top: 1rem;
  .systemlist {
    padding-top: 0.58rem;
    width: 16rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    font-family: "PingFang SC-Medium, PingFang SC";
    border-bottom: 0.02rem solid #ffd980;
    p {
      cursor: pointer;
      font-size: 0.24rem;
      font-weight: 600;
      line-height: 0.6rem;
      color: #666;
      width: 3.1rem;
      text-align: center;
    }
    .system_check {
      color: #ffd980;
      border-bottom: 0.04rem solid #ffd980;
    }
  }
  .system_c {
    width: 14.4rem;
    height: 5.52rem;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 0.49rem;
    box-shadow: 0.1rem 0.1rem 0.3rem 0.01rem rgba(0, 0, 115, 0.13);
    display: flex;
    justify-content: center;
    padding: 0.6rem 0.68rem;
    .system_x {
      padding-right: 0.23rem;
      overflow: hidden;
      h5 {
        font-size: 0.22rem;
        line-height: 0.33rem;
        margin-bottom: 0.24rem;
      }
      p {
        span {
          display: inline-block;
          width: 0.08rem;
          height: 0.08rem;
          margin-right: 0.1rem;
          border-radius: 50%;
        }
        font-size: 0.2rem;
        padding: 0.2rem 0;
        color: #666;
      }
      p:nth-child(2n) {
        span {
          background-color: #ffc53d;
        }
      }
      p:nth-child(2n + 1) {
        span {
          background-color: #ff7131;
        }
      }
    }
    img {
      width: 7.18rem;
      height: 4.52rem;
    }
  }
}
.aaaa{
  
  animation-name: move;
  /* 持续时间 */
  animation-duration: 3s;
  // animation-fill-mode: forwards;
}
.bbbb{
  /* 2.调用动画 */

  animation-name: movea;
  /* 持续时间 */
  animation-duration: 3s;
  // animation-fill-mode: forwards;
}
@keyframes move {
  /* 0%的内容可以不写，也可以直接不写0% */
  0% {
    position: relative;
     left: -400px;
  }
  25% {
   
    // transform: translatex(-1000px, 0);
  }
  50% {
    
  }
  75% {
    
  }
  100% {
    position: relative;
    left: 0px;
    // transform: translate(1000px, 0);
  }
}

@keyframes movea {
  /* 0%的内容可以不写，也可以直接不写0% */
  0% {
    position: relative;
     right:-400px;
  }
  25% {
   
    // transform: translatex(-1000px, 0);
  }
  50% {
    
  }
  75% {
    
  }
  100% {
    position: relative;
    right: 0px;
    // transform: translate(1000px, 0);
  }
}
</style>
