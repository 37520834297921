<template>
  <div>
    <div class="caselisting">
      <h2 class="heading">会员营销场景和行业案例</h2>
      <p class="title">
        微心动为酒店、长租式公寓、大中型连锁餐饮等众多行业提供专业的营销解决方案
      </p>
      <div class="scheme">
        <p :class="select == 1 ? 'select' : ''" @click="selectScheme(1)">
          营销场景方案
        </p>
        <p :class="select == 2 ? 'select' : ''" @click="selectScheme(2)">
          行业解决方案
        </p>
      </div>
      <!-- 营销场景方案 -->
      <div
        class="scheme_c"
        v-show="select == 1"
        :class="select == 1 ? 'ccc' : ''"
      >
        <div class="market_l">
          <p
            v-for="(v, index) in marketlist"
            :key="index"
            @click="onmarket(index)"
            :class="market == index ? 'select' : ''"
          >
            {{ v.name }}
          </p>
        </div>
        <div class="market_r" :class="show ? 'dddd' : ''">
          <h6>{{ marketlist[market].title }}</h6>
          <p>{{ marketlist[market].introduce }}</p>
          <div class="characteristic">
            <img
              v-for="(v, index) in marketlist[market].characteristic"
              :key="index"
              :src="v.imgurl"
            />
          </div>
          <div>
            <button @click="openapply()">申请试用</button>
            <button @click="ondetails(marketlist[market].rname, 2)">
              了解详情
            </button>
          </div>
        </div>
      </div>
      <!-- 行业解决方案 -->
      <div
        class="scheme_c"
        v-show="select == 2"
        :class="select == 2 ? 'ccc' : ''"
      >
        <div class="market_l">
          <p
            v-for="(v, index) in industrylist"
            :key="index"
            @click="onindustry(index)"
            :class="industry == index ? 'select' : ''"
          >
            {{ v.name }}
          </p>
        </div>
        <div class="market_r" :class="show ? 'dddd' : ''">
          <h6>{{ industrylist[industry].title }}</h6>
          <p>{{ industrylist[industry].introduce }}</p>
          <div class="characteristic">
            <img
              v-for="(v, index) in industrylist[industry].characteristic"
              :key="index"
              :src="v.imgurl"
            />
          </div>
          <div>
            <button @click="openapply()">申请试用</button>
            <button @click="ondetails(industrylist[industry].rname, 3)">
              了解详情
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      select: 1,
      marketlist: [
        {
          name: "数字化营销",
          title: "数字化营销营销场景方案",
          rname: "Digitization",
          introduce:
            "数字化营销的核心是能够进行大规模的精准个性化营销，微心动一体化营销云从数字化链接、数据管理和洞察到全渠道消费者互动、自动化智能营销以及敏捷营销实践，助力企业建立从方法论到实践落地的“数据驱动增长体系”，真正实现数字化营销增长模式。",
          characteristic: [
            {
              imgurl: require("../assets/images/sz01.png"),
            },
            {
              imgurl: require("../assets/images/sz02.png"),
            },
            {
              imgurl: require("../assets/images/sz03.png"),
            },
            {
              imgurl: require("../assets/images/sz04.png"),
            },
          ],
        },
        {
          name: "SCRM",
          title: "SCRM营销场景方案",
          rname: "SCRM",
          introduce:
            "微心动帮助用户搭建基于CDP客户数据平台+MA营销自动化的全域SCRM解决方案，与传统CRM系统相比，SCRM（Social CRM）是以营销互动为中心的全域客户管理系统，更偏重社交化粉丝的运营和管理，尤其是对微信生态粉丝运营需求的支持。",
          characteristic: [
            {
              imgurl: require("../assets/images/sc01.png"),
            },
            {
              imgurl: require("../assets/images/sc02.png"),
            },
            {
              imgurl: require("../assets/images/sc03.png"),
            },
            {
              imgurl: require("../assets/images/sc04.png"),
            },
          ],
        },
        {
          name: "私域流量运营",
          title: "私域流量运营营销场景方案",
          rname: "Flow",
          introduce:
            "私域流量是企业可以有效和主动触达的客户群体，本质是高质量的营销触达和客户关系管理。在获客成本日益高企的流量去中心化时代，越来越多的品牌开始意识到私域流量的DTC运营模式对于获客、留存和转化的重要性。微心动企业级私域流量运营解决方案，具备跨平台的流量整合及应用能力，能够统一管理全渠道私域用户的营销互动。",
          characteristic: [
            {
              imgurl: require("../assets/images/sy01.png"),
            },
            {
              imgurl: require("../assets/images/sy02.png"),
            },
            {
              imgurl: require("../assets/images/sy03.png"),
            },
          ],
        },
        {
          name: "营销活动管理",
          title: "营销活动管理营销场景方案",
          rname: "Activity",
          introduce:
            "基于MAP营销自动化平台，微心动可以帮助您管理和实现覆盖大规模人群和跨渠道的营销活动，在CDP客户数据平台和AD Hub广告效果管理平台的支持下，营销活动效果将会更精准，提供更好的用户个性化体验。",
          characteristic: [
            {
              imgurl: require("../assets/images/yx01.png"),
            },
            {
              imgurl: require("../assets/images/yx02.png"),
            },
            {
              imgurl: require("../assets/images/yx03.png"),
            },
            {
              imgurl: require("../assets/images/yx04.png"),
            },
          ],
        },
        {
          name: "会员营销",
          title: "会员营销营销场景方案",
          rname: "Member",
          introduce:
            "企业拥有多个渠道的会员数据已经成为常态，为了避免营销活动对会员的重复触达，实现统一的会员营销策略，有必要对分散在全渠道的会员数据进行统一管理。微心动连接所有的会员触点和平台，帮助您构建一体化会员系统，实现跨平台的会员私域流量运营模式。",
          characteristic: [
            {
              imgurl: require("../assets/images/hy01.png"),
            },
            {
              imgurl: require("../assets/images/hy02.png"),
            },
            {
              imgurl: require("../assets/images/hy03.png"),
            },
            {
              imgurl: require("../assets/images/hy04.png"),
            },
          ],
        },
      ],
      industrylist: [
        {
          name: "酒店行业",
          rname: "Hotelscheme",
          title: "酒店行业营销解决方案",
          introduce:
            "微心动通过搭建全渠道会员运营体系，帮助大中型酒店构建以客户为中心的私域流量运营体系，其中包括小程序、微信公众平台等会员运营体系，帮助酒店解决会员营销、SCMR管理、会员消费占比、营销活动等营销场景，为酒店降本提效的同时实现营收增长。",
          characteristic: [
            {
              imgurl: require("../assets/images/jd01.png"),
            },
            {
              imgurl: require("../assets/images/jd02.png"),
            },
            {
              imgurl: require("../assets/images/jd03.png"),
            },
            {
              imgurl: require("../assets/images/jd04.png"),
            },
          ],
        },
        {
          name: "长租式公寓",
          title: "长租式公寓营销解决方案",
          rname: "Apartmentscheme",
          introduce:
            "微心动帮助长租式公寓行业构建一体化线上客户服务体系，提供全流程的服务体系，构建以租户为中心的智能营销系统，有效提升租户的居住体验和效率，实现长租式公寓的数字化转型。",
          characteristic: [
            {
              imgurl: require("../assets/images/cz01.png"),
            },
            {
              imgurl: require("../assets/images/cz02.png"),
            },
          ],
        },
        {
          name: "大中型连锁餐饮",
          rname: "Cateringprogram",
          title: "大中型连锁餐饮营销解决方案",
          introduce:
            "对于现代化的大中型连锁餐饮来说，以会员为中心的精准营销结合全渠道的流量运营是常见的运营套路。微心动大中型连锁餐饮解决方案通过打造会员中心、外卖和包厢预定等会员营销场景，帮助企业构建会员私域流量池，精准营销。",
          characteristic: [
            {
              imgurl: require("../assets/images/dz01.png"),
            },
            {
              imgurl: require("../assets/images/dz02.png"),
            },
          ],
        },
      ],
      market: 0,
      industry: 0,
      show: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    selectScheme(i) {
      this.select = i;
    },
    onmarket(i) {
      let that = this;
      this.market = i;
      this.show = true;
      let aa = setTimeout(() => {
        that.show = false;
        clearInterval(aa);
      }, 3000);
      aa;
    },
    onindustry(i) {
      let that = this;

      this.industry = i;
      this.show = true;
      let aa = setTimeout(() => {
        that.show = false;
        clearInterval(aa);
      }, 3000);
      aa;
    },
    openapply() {
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
    ondetails(a, b) {
      this.$router.push({
        name: a,
        query: {
          id: b,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
// 会员营销场景和行业案例
.caselisting {
  box-sizing: border-box;
  margin-top: 1rem;
  height: 10.46rem;
  background-image: url(../assets/images/333.png);
  background-size: 100% 100%;
  color: #fff;
  padding: 0.83rem 2.31rem;
  .heading {
    color: #fff;
  }
  .title {
    font-size: 0.18rem;
    text-align: center;
  }
  .scheme {
    font-size: 0.2rem;
    display: flex;
    width: 4.32rem;
    margin: 0.58rem auto 0.48rem;

    p {
      width: 2.16rem;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #ffffff;
      text-align: center;
      padding: 0.18rem 0;
      cursor: pointer;
    }
    p:nth-child(2) {
      border-radius: 0px 4px 4px 0px;
    }
  }
  .ccc {
    animation-name: move;
    /* 持续时间 */
    animation-duration: 2s;
    // animation-fill-mode: forwards;
    overflow: hidden;
  }
  @keyframes move {
    /* 0%的内容可以不写，也可以直接不写0% */
    0% {
      height: 0%;
    }
    25% {
    }
    50% {
      height: 50%;
    }
    75% {
    }
    100% {
      height: 6.34rem;
    }
  }

  .scheme_c {
    display: flex;
    height: 6.34rem;
    .market_l {
      padding: 0.15rem 0.4rem;
      border-right: 0.02rem solid #fff;
      p {
        font-size: 0.2rem;
        width: 1.72rem;
        line-height: 0.62rem;
        text-align: center;
        cursor: pointer;
        margin-bottom: 0.36rem;
        border-radius: 0.04rem;
      }
    }
    .market_r {
      padding: 0.15rem 0 0 1.2rem;
      h6 {
        font-size: 0.22rem;
        padding-bottom: 0.18rem;
      }
      p {
        line-height: 0.27rem;
        font-size: 0.18rem;
      }
      .characteristic {
        margin-top: 0.64rem;
        img {
          height: 1.08rem;
          margin-right: 0.9rem;
        }
      }
      button {
        width: 1.6rem;
        height: 0.52rem;
        background-color: transparent;
        color: #fff;
        border: 0.02rem solid #fff;
        border-radius: 0.04rem;
        font-size: 0.18rem;
      }
      button:nth-child(1) {
        background-color: #ffc53d;
        border: 0;
        margin-right: 0.41rem;
      }
    }
  }
}

.select {
  background-color: #ffc53d;
  border: 0 !important;
}

.dddd {
  /* 2.调用动画 */

  animation-name: move1;
  /* 持续时间 */
  animation-duration: 3s;
  // animation-fill-mode: forwards;
}
@keyframes move1 {
  /* 0%的内容可以不写，也可以直接不写0% */
  0% {
    position: relative;
    right: -10rem;
  }
  25% {
    // transform: translatex(-1000px, 0);
  }
  50% {
  }
  75% {
  }
  100% {
    position: relative;
    right: 0rem;
    // transform: translate(1000px, 0);
  }
}
</style>
